<template>
  <div class="snapsheet-template-card sport-card" 
    :title="template.title"
    :class="{ active: isActive }"
    @click="setActiveTemplate()">
    <img class="snapsheet-template-card-svg" :src="'/images/snapsheet/' + template.slug + '.svg'">
    <p>{{ template.title }}</p>
  </div>
</template>

<script>
export default {
  name: "SnapsheetTemplateCard",
  computed: {
    isActive() {
      return this.activeTemplates.indexOf(this.template.slug) >= 0;
    }
  },
  methods: {
    setActiveTemplate() {
      this.$emit("setActiveTemplate", this.template);
    }
  },
  props: ["template", "activeTemplates"],
}
</script>

<style lang="scss" scoped>
  #app {
    .active {
      border-color: #bd060f;
    }
    .snapsheet-template-card {
      position: relative;
      .snapsheet-template-card-svg {
        width: 80%;
        margin: -5px 5px;
      }
      p {
        position: absolute;
        bottom: -2px;
        margin: 0;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.7);
        width: 57px;
        left: -2px;
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        color: white;
        font-weight: bold;
      }
    }
  }
</style>