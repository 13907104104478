<template>
  <div class="color-picker mr-auto">
    <div
      class="color-picker-swatch flex align-center"
      @click.stop="triggerPicker"
    >
      <div
        class="color-picker-radius box-shadow"
        v-bind:style="{ backgroundColor: selected.hex }"
      ></div>
      <span>{{ selected.family_name }}</span>
    </div>
    <div
      class="color-picker-list box-shadow flex-column flex-wrap"
      v-bind:class="{ active: isActive }"
    >
      <!-- Create a ColorSwatch for each color available in the state -->

      <template v-if="!isSnapsheetSelected">
        <!-- Color pickers for showrooms and Playbooks -->
        <ColorSwatch
          v-bind:key="color.id"
          v-for="color in colors"
          :color="color"
          :field="field"
          v-on="{ emitSelectColor }"
        />
      </template>

      <template v-if="isSnapsheetSelected">
        <!-- Color pickers for Snapsheets -->
        <ColorSwatch
          v-bind:key="color.id"
          v-for="color in snapsheetsColors"
          :color="color"
          :field="field"
          v-on="{ emitSelectColor }"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ColorSwatch from "../components/ColorSwatch.vue";
import {mapState} from 'vuex'
export default {
  props: ["selected", "field", "isActive", "isSnapsheetSelected"],
  name: "ColorPicker",
  computed: {
    ...mapState(['snapsheetColorsIds']),
    colors() {
      return this.$store.state.dealer.colors;
    },
    snapsheetsColors() {
      return this.colors.filter((color) =>
        this.snapsheetColorsIds.some((colorId) => colorId == color.id)
      );
    },
  },
  methods: {
    triggerPicker() {
      this.$emit("on-open-picker", this.field);
    },
    emitSelectColor(data) {
      this.$emit("emitSelectColor", data);
    },
  },
  components: {
    ColorSwatch,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .color-picker-swatch {
    .color-picker-radius {
      width: 20px;
      height: 20px;
      border: 1px solid;
    }
  }
  .color-picker-list {
    max-height: 303px;
  }

  .color-picker {
    position: relative;
  }

  .color-picker-swatch {
    cursor: pointer;
    .color-picker-radius {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid;
    }
    span {
      //font-family: $ubuntuRegular;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 0.76px;
      text-transform: lowercase;
      color: var(--buttonNotSelectedColor);
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  .color-picker-list {
    background-color: white;
    background: "#FFFFFF";
    width: 215px;
    max-height: 248px;
    display: none;
    padding: 2.5px 0 3.5px;
    margin-top: 5px;
    position: absolute;
    top: 20px;
    left: 0;

    .color-picker-swatch {
      padding: 3.5px 9px;
    }
  }

  .color-picker-list.active {
    display: flex;
    position: absolute;
    z-index: 1;
  }
}
</style>