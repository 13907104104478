<template>
  <div
    id="header-main"
    class="header-main-class kt-grid__item header-main-class--fixed"
    data-ktheader-minimize="on"
  >
    <div class="header-main-class__bottom">
      <div class="header-item-container m-auto" >
        <!-- Gameplan logo -->
        <img
          class="gameplan-logo"
          src="/images/bsn-logo.png"
          alt="Gameplan Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
  props: ["dealer"],
  methods: {
  },
  components: {
  },
  computed: {
    logo() {
      return this.dealer.logo;
    }
  },
};
</script>

<style lang="scss" scoped>
#header-main {
  .header-item-container {
    height: 50px;
    display: flex;
    justify-content: center;
    .gameplan-logo {
      padding: 5px;
      max-height: 100%;
    }
  }
  .header-main-class__bottom {
    background-color:black;
    display: flex;
    flex-flow: column;

    .header-main-class-menu-wrapper {
      background-color: unset;
      padding: 0;

      &.visible {
        left: 0;
      }
      .kt-menu__nav {
        @media screen and (max-width: 1024px) {
          padding: 20px 0 30px 0;
        }
        .kt-menu__item {
          &:hover {
            ::v-deep.kt-menu__link {
              @media screen and (max-width: 1024px) {
                span {
                  color: black;
                }
              }
            }
          }
          ::v-deep.kt-menu__link {
            @media screen and (max-width: 1500px) {
              padding: 0;
            }

            @media only screen and (min-width: 1025px) {
              border-right: 1px solid #444444;
            }
            border-radius: 0;
            padding: 0 20px;
            @media only screen and (max-width: 1500px) {
              padding: 0 12px;
            }
            @media only screen and (max-width: 1280px) {
              padding: 0 5px;
            }
            span {
              color: white;
              @media only screen and (max-width: 1280px) {
                font-size: 12px;
              }
            }
            .kt-menu__link-text {
              font-family: "ForzaBold";

              @media screen and (max-width: 1024px) {
                display: block;
                text-align: center;
                height: 50%;
                margin: auto;
              }
            }
          }
          &:last-child {
            ::v-deep.kt-menu__link {
              border-right: none;
            }
          }
          &.kt-menu__item--open {
            ::v-deep.kt-menu__link {
              background-color: unset;
              @media screen and (max-width: 1024px) {
                &,
                &:hover {
                  background-color: white;
                  color: black !important;
                  span.kt-menu__link-text {
                    color: black !important;
                  }
                }
              }

              span {
                @media screen and (min-width: 1025px) {
                  padding-top: 1px;
                  border-bottom: 1px solid white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.header-menu-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#create-project-desktop-button {
  display: none;
  @media screen and (min-width: 1025px) {
    display: block;
  }
}

#create-project-mobile-button {
  display: none;
}

#create-project-desktop-button,
#create-project-mobile-button {
  background: transparent;
  /* font-family: "SlateStd" !important; */
  border: 2px solid white;
  text-transform: capitalize !important;
  border-radius: 3px;

  &:hover{
    background: white;
    color: black;
  }
}

@media screen and (max-width: 1024px) {
  .header-item-container {
    width: 100vw;
    height: 50px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #header-main_menu_mobile_open_btn,
  #header-main_menu_mobile_close_btn {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    width: 40px;
    height: 40px;
    position: initial;
    top: 0;
    top: 1px;
    left: 1rem;
    background: black;
    border: 1px solid white;
    z-index: 1002;
    transition: left 0.3s ease, right 0.3s ease;
    /* position: fixed; */
    border: 0;
    box-shadow: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none !important;

    padding: 15px;

    &.close {
      background: red;
      justify-content: center;
      opacity: 1;
      i.la.la-close {
        color: white;
      }
    }

    .line {
      width: 15px;
      height: 2px;
      background-color: white;
    }
  }

  .open-button-container {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    #header-main_menu_mobile_open_btn {
      position: relative;
      width: 30%;
      padding: 15px 0;
      font-family: Forza;
      margin: initial;
      p {
        color: white;
        margin: 0;
        margin-right: 0.7rem;
        font-family: "ForzaBold";
        text-transform: uppercase;
      }
      .lines-container {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  figure {
    display: flex;
    height: 100%;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin: 0;
    .gameplan-logo-mobile {
      display: block;
      min-width: 145px;

      @media screen and (max-width: 576px) {
        width: 145px;
      }
      margin: auto;
    }
  }

  #create-project-mobile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 75%;
  }
  .header-menu-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #header-main_menu {
    background-color: black;
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      #header-main_menu_mobile_close_btn {
        margin-left: 15px;
        margin-top: 10px;
      }
    }
  }

  li.kt-menu__item.kt-menu__item--rel {
    display: flex;
    border-bottom: 1px solid grey;
    justify-content: center;
    align-items: center;
    flex-flow: row;

    a {
      span {
        display: block;
        text-align: center;
        height: 50%;
        margin: auto;
      }
    }
  }
}
</style>