<template>
  <div id="app">
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
      >
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
          id="kt_wrapper"
        >
          <HeaderMenu :dealer="dealer" v-if="!loading_dealer" />
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
          >
            <div
              class="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              <div
                class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                id="kt_content"
              >
                <!-- begin:: Content -->
                <router-view v-if="has_dealer_info" />
                
                <!-- end:: Content -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderMenu from "@/components/Header/HeaderMenu.vue";

export default {
  data() {
    return {
      forbidden: false,
      //params
      accountid: "",
      token: "",
      teamworkid: "",
      allesonid: "",
      email: "",
      company: "",
      terms: "",
      pricegroup: "",
      carriedt: "",
      carriernum: "",
    };
  },
  computed: {
    ...mapState(["dealer"]),

    loading_dealer() {
      return !this.has_dealer_info;
    },

    has_dealer_info() {
      return this.dealer.api_token && Object.keys(this.dealer).length > 0;
    },
    
  },

  created() {
    //let url = new URL(window.location.href);
    this.getDealer();
    console.log('a')
  },

  methods: {
    ...mapActions(["getDealer", "updateDealer"]),

    // if (this.$router.currentRoute.name === "Forbidden") return false; (USED IN get_dealer())
  },

  components: {
    HeaderMenu,
  },
};
</script>

<style lang="scss">
$font-dir: "/fonts/";
@font-face {
  font-family: "Forza";
  src: url("#{$font-dir}Forza-Medium.otf");
}
@font-face {
  font-family: "ForzaBold";
  src: url("#{$font-dir}Forza-Bold.otf");
}
@font-face {
  font-family: "SlateStd";
  src: url("#{$font-dir}SlateStd.otf");
}
@font-face {
  font-family: "SlateStd-Bold";
  src: url("#{$font-dir}SlateStd-Bold.otf");
}
body {
  margin: 0;
}
#app {
  .toast-error {
    opacity: 1 !important;
  }
  #kt_content {
    padding: 0;
  }
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn {
    font-family: "ForzaBold";
  }
  div,
  p,
  span,
  a,
  label,
  input,
  textarea,
  select {
    font-family: "SlateStd";
  }
  strong {
    font-family: "SlateStd-Bold";
  }
  .required {
    color: #fd397a;
  }
  .hidden {
    display: none;
  }
  .kt-grid--root {
    justify-content: space-around;
    min-height: 100vh;
  }
  .full-width {
    width: 100%;
  }
  .flex {
    display: flex;
  }
  .flex-auto {
    flex: auto;
  }
  .flex-center,
  .justify-center {
    justify-content: center;
  }
  .flex-between,
  .justify-between {
    justify-content: space-between;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-end,
  .justify-end {
    justify-content: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }
  textarea {
    resize: none;
  }
  .switch-group {
    display: flex;
    align-items: center;
  }
  .margin-0 {
    margin: 0;
  }
}
.sign-up-form {
  margin-top: 30px;
}
.forbidden {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.loading-spinner {
  margin: 50px 0;
}
</style>