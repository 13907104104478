import axios from "axios"

const dealer = {
  state: {
    accountid: '000151',
    alleson_id: null,
    api_token: "",
    campaigns: [],
    can_order: 0,
    carriedt: null,
    carrier_num: null,
    checkout_type: "",
    class_name: null,
    colors: [],
    email: "",
    fsg_code: "",
    id: "",
    logo: "",
    name: "",
    omg_account_number: "",
    phone: "",
    price_group: "",
    reps: "",
    request_quote: "",
    rhino_code: "",
    passcode: "",
  },

  getters: {

    getAllColors: (state) => {
      return state.colors;
    },

    getColorById: (state) => (id) => {
      for (let index = 0; index < state.colors.length; index++) {
        const color = state.colors[index];
        if (color.id === id) {
          return color;
        }
      }
      return false;
    },

    getColorByName: (state) => (colorName) => {
      for (let index = 0; index < state.colors.length; index++) {
        const color = state.colors[index];
        if (color.name.toLowerCase() === colorName.toLowerCase()) {
          return color;
        }
      }
      return false;
    },

    getColorHex: (_state, getters) => (id) => {
      const color = getters.getColorById(id)
      return color && color.hex
    },

    getColorName: (_state, getters) => (id) => {
      const color = getters.getColorById(id)
      return color && color.name
    },

    getAllSports: (state) => {
      return state.catalogs;
    },

  },

  actions: {

    async getDealer({ state, rootState, commit, dispatch }) {
      axios
        .get(rootState.settings.endpoint + "api/v2/dealer", {
          params: {
            code: state.accountid,
          },
        })
        .then((resp) => {
          commit("setDealer", resp.data);
        })
        .catch((error) => {
          dispatch("showToast", {
            txt: error,
            type: "error",
          }, { root: true });
        });
    },

    async updateDealer({ rootState, state, commit, dispatch }, formData) {
      formData.append("_method", "put");
      await axios(
        {
          method: 'POST',
          url: rootState.settings.endpoint + "api/v2/dealer",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + state.api_token,
          }
        }
      )
        .then((response) => {
          dispatch("showToast", {
            txt: "Information updated.",
          })
            commit("updateDealer", response.data)
        })
        .catch((e) => {
          dispatch("showToast", {
            txt: e,
            type: "error",
          }, { root: true });
        });
    },

    async getAllReps({ state, rootState, commit, dispatch }) {
      try {
        const { data } = await axios.get(rootState.settings.endpoint + "api/v2/reps", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.api_token,
          },
        })
        commit("setAllReps", data)
      } catch (error) {
        dispatch("showToast", {
          txt: error,
          type: "error",
        }, { root: true });
      }
    },

    async addNewSalesRep({ rootState, state, commit, dispatch }, params) {
      await axios
        .post(rootState.settings.endpoint + "api/v2/rep", params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.api_token,
          },
        })
        .then(({ data }) => {
          dispatch("showToast", {
            txt: '"' + params.name + '" added successfully.',
          }, { root: true });
          commit("setNewSalesRep", data);
        })
        .catch((e) => {
          dispatch("showToast", {
            txt: e,
            type: "error",
          }, { root: true })
        })
        .finally(document.getElementById("closeRepModal").click())
    },

    postRepsModal({ commit, dispatch }, data) {
      let params = {
        name: data.modalName,
        job_title: data.modalJobTitle,
        email: data.modalEmail,
        phone: data.modalPhone,
        _method: "post",
      };
      if (data.modalUserId) {
        params.user_id = data.modalUserId;
        params._method = "put";
      }
      document.getElementById("closeRepModal").click();
      axios
        .post(this.state.settings.endpoint + "api/v2/rep", params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.dealer.api_token,
          },
        })
        .then((response) => {
          if (data.modalUserId) {
            dispatch("showToast", {
              txt: '"' + data.modalName + '" edited successfully.',
            });
            commit("editSalesRep", params);
          } else {
            dispatch("showToast", {
              txt: '"' + data.modalName + '" added successfully.',
            });
            commit("addSalesRep", response.data);
          }
        })
        .catch((e) => {
          dispatch("showToast", {
            txt: e,
            type: "error",
          });
        });
    },

    async editSalesRep({ rootState, state, commit, dispatch }, params) {

      await axios
        .post(rootState.settings.endpoint + "api/v2/rep", params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.api_token,
          },
        })
        .then(() => {
          dispatch("showToast", {
            txt: '"' + params.name + '" edited successfully.',
          }, { root: true });
          commit("updateSalesRep", params);
        })
        .catch((e) => {
          dispatch("showToast", {
            txt: e,
            type: "error",
          }, { root: true })
        })
        .finally(document.getElementById("closeRepModal").click())
    },

    async removeSalesRep({ rootState, state, commit, dispatch }, rep) {

      await axios
        .delete(rootState.settings.endpoint + "api/v2/rep", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.api_token,
          },
          data: {
            user_id: rep.id,
          },
        })
        .then(() => {
          dispatch("showToast", {
            txt: '"' + rep.name + '" removed',
          }, { root: true });
          commit("deleteSalesRep", rep.id);
        })
        .catch((e) => {
          dispatch("showToast", {
            txt: e,
            type: "error",
          }, { root: true });
        });

    },

    addTeamLink({ commit }, link) {
      commit('addTeamLink', link)
    }

  },

  mutations: {

    addTeamLink: (state, link) => {
      state.teamlinks.push(link);
    },

    /*
      Copy all values from the data object that came from the API to the state, 
      and so we have our dealer information loaded :D
    */
    setDealer: (state, data) => {
      Object.assign(state, data)
    },

    updateDealer: (state, data) => {
      if (!state.settings) state.settings = {};
      Object.assign(state.settings, data.settings)
      state.logo = data.logo;
      state.passcode = data.passcode;
    },

    setAccountNumber: (state, data) => {
      state.omg_account_number = data.omgAccountNumber;
    },

    setTeamLink: (state, link) => {
      state.teamlinks.push(link);
    },

    setAllReps: (state, data) => {
      state.reps = data
    },

    setNewSalesRep: (state, rep) => {
      state.reps.push(rep);
    },

    updateSalesRep: (state, params) => {
      for (let index = 0; index < state.reps.length; index++) {
        const element = state.reps[index];
        if (element.id === params.user_id) {
          state.reps[index].name = params.name;
          state.reps[index].email = params.email;
          state.reps[index].job_title = params.job_title;
          state.reps[index].phone = params.phone;
          break;
        }
      }
    },

    deleteSalesRep: (state, repId) => {
      for (let index = 0; index < state.reps.length; index++) {
        const element = state.reps[index];
        if (element.id === repId) {
          state.reps.splice(index, 1);
          break;
        }
      }
    }
  }
}

export default dealer