<template>
  <div class="col-12">
    <select
      class="form-control border-radius-grey selection-section"
      :value="selectedLayout"
      @change="emitSelectLayout"
      @focus="emitSetActiveTab"
      :class="{ 'is-invalid': errors.snapsheetSelectedLayout }"
    >
      <option :value="0">Select a layout</option>
      <option
        v-for="layoutValue in layoutsValues"
        :value="Number(layoutValue)"
        v-bind:key="layoutValue + '-snap-ly'"
      >
        {{ layoutValue }} Custom Products
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SnapsheetLayoutDropdown",
  props: {
    layoutsValues: { type: Array },
    selectedLayout: { type: Number },
    errors: { type: [Object, Array] },
  },
  methods: {
    emitSelectLayout(e) {
      this.$emit("selectLayout", e.target.value);
    },
    emitSetActiveTab() {
      this.$emit("setActiveTab", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
#layout-card {
  width: 57px;
  height: 53px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 3px;
  margin: 5px;
  padding: 2px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    span {
      color: var(--snapsheetColor);
    }
  }

  span {
    color: #000000;
    font-size: 8px;
    /* line-height: 9px; */
    text-transform: uppercase;
    opacity: 0.5;
    /* margin-top: 5px; */
    font-size: 3.5rem;
    font-weight: 700;
    font-family: "ForzaBold" !important;
    margin: auto;
  }
}
</style>