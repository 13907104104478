<template>
  <form
    @submit.prevent
    autocomplete="off"
    class="search-container input-group-column col-12"
  >
    <label class="text-black" for="school"> Search your school:<span class="required">*</span> </label>
    <div class="kt-input-icon kt-input-icon--left">
      <input
        class="form-control"
        type="text"
        name="school"
        placeholder="Search your school..."
        :value="schoolKeyword"
        @input="$emit('input', $event.target.value)"
        @focus="showResults()"
        @blur="hideResults()"
        ref="searchInput"
      />
      <span class="kt-input-icon__icon kt-input-icon__icon--left">
        <span>
          <i class="la la-search"></i>
        </span>
      </span>
    </div>

    <ul
      :class="{
        active: schoolKeyword.length >= 3 && !isHideResults,
      }"
      @focus="showResults()"
      class="results-list"
    >
      <!-- LOADING MESSAGE -->
      <li class="results-list__item disabled" v-if="loading">
        <p>Loading...</p>
      </li>
      <!-- NOT RESULTS MESSAGE -->
      <li
        class="results-list__item disabled"
        v-else-if="!loading && !schoolsList.length"
      >
        <p>Not results</p>
      </li>
      <!-- RESULTS LIST -->
      <template v-else>
        <li
          class="results-list__item"
          v-for="school in schoolsList"
          :key="school.ID + '-id'"
          @click="emitSchoolSelected(school)"
          @keyup.enter="emitSchoolSelected(school)"
          @focus="showResults()"
          tabindex="0"
        >
          {{ school.complete_name }}
        </li>
      </template>
    </ul>
  </form>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "SchoolSearchBar",
  props: ["selectedSchool", "isVisible", "schoolKeyword"],
  data() {
    return {
      loading: false,
      schoolsList: [],
      isHideResults: false,
    };
  },
  computed: {
    ...mapState(["dealer", "settings"]),
  },

  created() {
    //debounce allows us to limitate the frecuency that the fetchSchools function can be called, in this case, it will be called every 700ms at max
    this.debouncedFetchSchools = debounce(this.fetchSchools, 700);
  },

  methods: {
    async fetchSchools() {
      this.loading = true;
      this.schoolsList = [];
      try {
        const url = this.settings.endpoint + "api/v2/schools";
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.dealer.api_token,
          },
          params: {
            query: this.schoolKeyword,
          },
        };
        const { data } = await axios.get(url, options);
        this.schoolsList = data.map((school) => school);
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    emitSchoolSelected(school) {
      this.$emit("onSchoolSelected", school);
      this.hideResults();
    },
    showResults() {
      this.isHideResults = false;
      this.$emit("emitSetActiveTab");
    },
    hideResults() {
      this.isHideResults = true;
    },
  },

  watch: {
    //When the keyword change, it will trigger the API call
    schoolKeyword(newKeyword) {
      if (newKeyword !== this.selectedSchool.complete_name) {
        this.loading = true;
        if (newKeyword.length >= 3) {
          this.debouncedFetchSchools();
        } else {
          this.loading = false;
        }
      }
    },

    //When the bar is visible, focus on it
    isVisible(visible) {
      if (visible) this.$refs.searchInput.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;

  label {
    color: black;
    font-size: 1.2rem;
  }

  .form-control {
    border: 1px solid var(--buttonNotSelectedColor);
  }

  ul.results-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    top: 63px;
    left: 0;
    max-height: 200px;
    width: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s;
    border-radius: 5px;
    border: 1px solid #e2e5ec;
    box-shadow: -1px 4px 10px -1px rgba($color: #000000, $alpha: 0.35);
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;

    &.active {
      transform: scaleY(1);
    }

    li.results-list__item {
      padding: 0.5rem;
      background: white;
      border-bottom: 1px solid #cfcfcf;
      color: black;
      cursor: pointer;

      &.disabled {
        cursor: initial;
        pointer-events: none;
      }

      p {
        margin: 0;
      }
    }
  }
}
</style>
