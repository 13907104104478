<template>
  <main class="container-fluid mt-4">
    <!--  -->
    <!-- Project Form -->
    <CreateNewProjectForm
      :school="selectedSchool"
      :reps="reps"
      :typeOfProjectSelected="typeOfProjectSelected"
      :playbookCampaigns="playbookCampaigns"
      :showroomCampaigns="showroomCampaigns"
    />
  </main>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CreateNewProjectForm from "@/components/CreateNewProject/CreateNewProjectForm";

export default {
  name: "CreateNewProject",
  components: { CreateNewProjectForm },
  data() {
    return {
      typeOfProjectSelected: "playbook",
      selectedSchool: "",
      //Playbook and showrooms sports card
      playbookCampaigns: [],
    };
  },
  computed: {
    ...mapState(["dealer", "settings"]),
    reps() {
      return this.dealer.reps;
    },
    showroomCampaigns() {
      return this.dealer.campaigns;
    },
  },
  methods: {
    isSelected(typeOfProject) {
      //console.log(this.typeOfProjectSelected)
      //console.log(this.typeOfProjectSelected)
      //console.log(this.typeOfProjectSelected)
      return typeOfProject === this.typeOfProjectSelected;
    },

    handleSetSchool(school) {
      this.selectedSchool = school;
    },

    getPlaybookCampaigns() {
      axios
        .get(this.settings.playbookEndpoint + "sports", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.playbookCampaigns = response.data;
        })
        .catch((error) => {
          this.$store.dispatch("showToast", {
            txt: error,
            type: "error",
          });
        });
    },
  },
  mounted() {
    const queryParam = this.$route.query.type,
      projectTypes = ["playbook", "snapsheet", "showroom"];
    if (queryParam && projectTypes.indexOf(queryParam) >= 0) {
      this.typeOfProjectSelected = queryParam;
    }
    this.$store.commit("setActivePage", "create_project");
    this.getPlaybookCampaigns();
/*    window.scrollTo({
      top: document.getElementById("kt_header").offsetTop,
      behavior: "smooth",
    });*/
  },
};
</script>

<style lang="scss" >
:root {
  --showroomColor: #0a80ed;
  --showroomColorHover: #0872d4;
  --showroomColorActive: #2b92f3;
  --playbookColor: #059c37;
  --playbookColorHover: #2faa4c;
  --playbookColorActive: #06c044;
  --snapsheetColor: #d5000b;
  --snapsheetColorHover: #bd060f;
  --snapsheetColorActive: #dd1e28;
  --buttonNotSelectedColor: #707070;
}

h2.text-black {
  color: black;
  font-size: 1.4rem;
  font-family: "SlateStd" !important;
  font-weight: bold;
}

/* Customize the label (the label-box) */
.label-box {
  position: relative;
  padding-left: 1rem;
  border: 2px solid var(--buttonNotSelectedColor);
  border-radius: 5px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  font-family: "SlateStd" !important;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    font-family: "SlateStd" !important;
    font-weight: bold;
    margin: auto;
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  &.btn.btn-outline-primary {
    background: transparent;
    color: black;
    border-color: var(--buttonNotSelectedColor);
    svg {
      fill: black;
    }
  }

  /* Showroom button*/

  &.showroom-btn.btn-primary {
    background: var(--showroomColor);
    border-color: var(--showroomColor);
  }
  &.showroom-btn.btn-primary:hover {
    background: var(--showroomColorActive);
  }
  &.showroom-btn.btn-primary,
  &.showroom-btn.btn-outline-primary:hover {
    span {
      color: white;
    }
    svg {
      fill: white;
    }
  }
  &.showroom-btn.btn-outline-primary:hover {
    background: var(--showroomColorHover);
    border-color: var(--showroomColorHover);
    color: white;
  }

  /* Playbook button */
  &.playbook-btn.btn-primary {
    background: var(--playbookColor);
    border-color: var(--playbookColor);
  }
  &.playbook-btn.btn-primary:hover {
    background: var(--playbookColorActive);
  }
  &.playbook-btn.btn-primary,
  &.playbook-btn.btn-outline-primary:hover {
    span {
      color: white;
    }
    svg {
      fill: white;
    }
  }
  &.playbook-btn.btn-outline-primary:hover {
    background: var(--playbookColorHover);
    border-color: var(--playbookColorHover);
    color: white;
  }

  /* Snapsheet Button */
  &.snapsheet-btn.btn-primary {
    background: var(--snapsheetColor);
    border-color: var(--snapsheetColor);
  }
  &.snapsheet-btn.btn-primary:hover {
    background: var(--snapsheetColorActive);
  }
  &.snapsheet-btn.btn-primary,
  &.snapsheet-btn.btn-outline-primary:hover {
    span {
      color: white;
    }
    svg {
      fill: white;
    }
  }
  &.snapsheet-btn.btn-outline-primary:hover {
    background: var(--snapsheetColorHover);
    border-color: var(--snapsheetColorHover);
    color: white;
  }

  /* SVG */

  &.btn-primary svg {
    width: 35px;
    fill: white;
  }

  & svg {
    height: 35px;
    width: 35px;
    fill: #5867dd;
    /* margin-left: 1rem; */
  }

  /* &:hover svg {
    fill: white;
  } */
}
</style> />