<template>
  <div class="snapsheet-template-cards">
    <SnapsheetTemplateCard 
      v-for="(template, index) in templates"
      v-on="{ setActiveTemplate }"
      :key="index"
      :template="template"
      :activeTemplates="activeTemplates"
    />
  </div>
</template>

<script>
import SnapsheetTemplateCard from "@/components/SnapsheetTemplateCard";
export default {
  name: "SnapsheetTemplateCards",
  components: {
    SnapsheetTemplateCard,
  },
  methods: {
    setActiveTemplate(template) {
      this.$emit("setActiveSnapsheetTemplate", template.slug);
    }
  },
  mounted() {
    this.$emit("setActiveSnapsheetTemplate", this.templates[0].slug);
  },
  data() {
    return {
      templates: [
        { title: "T-SHIRTS", slug: "tees"},
        { title: "L/S TEES", slug: "lstees"},
        { title: "FLEECE", slug: "fleece"},
        { title: "HOODIES", slug: "hoodies"},
        { title: "OUTERWEAR", slug: "outwear"},
        { title: "1/4 ZIPS", slug: "zips"},
        { title: "POLOS", slug: "polos"},
        { title: "SHORTS", slug: "shorts"},
        { title: "MASKS", slug: "masks"},
        /*"tees",
        "hoodies",
        "zips",
        "polos",
        "pants",*/
      ]
    }
  },
  props: ["activeTemplates"],
}
</script>

<style lang="scss" scoped>
  #app {
    .snapsheet-template-cards {
      display: flex;
      flex-wrap: wrap;
      margin: 0 8px;
    }
  }
</style>