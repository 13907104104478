import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';

//Modules
import dealer from "./modules/dealer"

//Toast setings
import toastr from "toastr";
toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-full-width",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

Vue.use(Vuex)

export default new Vuex.Store({

  modules: { dealer },

  state: {
    settings: {
      playbookEndpoint: process.env.VUE_APP_PLAYBOOK_ENDPOINT || "https://fsg.playbook.axlot.com/",
      endpoint: process.env.VUE_APP_ENDPOINT,
      builder: process.env.VUE_APP_BUILDER,
      snapsheetEndpoint: process.env.VUE_APP_SNAPSHEET_URL,
      fsajax: process.env.VUE_APP_FS_AJAX,
    },
    loading: true,
    active_page: '',
    snapsheetColorsIds: [5, 6, 7, 8, 10, 12, 14, 18, 19, 20, 21, 22, 23, 25],
  },

  getters: {
    getColorHexByField: (state) => (data) => {
      let color_id = !data.side || !data.subfield ? state[data.product_type + '_product'][data.field] : state[data.product_type + '_product'][data.side][data.field][data.subfield];
      //let color = state.colors[color_id];
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element.hex;
        }
      }
      return '#FFFFFF'; //White placeholder when data still didn't come from the API
    },
  },

  mutations: {
    setActivePage: (state, page) => {
      state.active_page = page;
    },
    setLoading: (state, loadingState) => {
      state.loading = loadingState
    }
  },

  actions: {

    setLoading({ commit }, loadingState) {
      commit('setLoading', loadingState)
    },

    redirectToPage(_e, page) {
      let name = page.name;
      router.push({
        name: name,
      });
    },

    showToast(_e, msg) {
      let type = msg.type || "success";
      switch (type) {
        case "success": {
          toastr.success(msg.txt);
          break;
        }
        case "info": {
          toastr.info(msg.txt);
          break;
        }
        case "warning": {
          toastr.warning(msg.txt);
          break;
        }
        case "error": {
          toastr.error(msg.txt);
          break;
        }
      }
    },

  },

})