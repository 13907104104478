<template>
  <div class="col-lg-4">
    <div class="wiz-container row">
      <div
        class="wiz-nav-item col-12"
        :class="{
          active: active_tab === 1,
          showroom: isShowroomSelected,
          playbook: isPlaybookSelected,
          snapsheet: isSnapsheetSelected,
        }"
      >
        <span>1</span>{{ stepOneTitle }}
      </div>

      <MoreInfoModal v-show="moreInfoModalOpen" v-on="{ closeMoreInfoModal }" />

      <!-- Radio Buttons / Scracth or School search -->
      <div class="selection-section col-12" v-if="!isSnapsheetSelected">
        <div class="form-group m-0">
          <!-- Form Container -->
          <div
            class="school-scratch-selection-container row align-items-center"
          >
            <!-- Select radio School search -->
            <label
              class="choose-label m-0 text-dark"
              :class="{
                active: inputMode === 'schoolSearch',
                showroom: isShowroomSelected,
                playbook: isPlaybookSelected,
                snapsheet: isSnapsheetSelected,
              }"
            >
              <input
                type="radio"
                value="schoolSearch"
                v-model="inputMode"
                :checked="inputMode === 'schoolSearch'"
                @focus="emitSetActiveTab"
              />
              Search by school
            </label>

            <span class="col-1 m-0 text-center">Or</span>

            <!-- Select radio Start from scratch -->
            <label
              class="choose-label m-0"
              :class="{
                active: inputMode === 'scratch',
                showroom: isShowroomSelected,
                playbook: isPlaybookSelected,
                snapsheet: isSnapsheetSelected,
              }"
            >
              <input
                type="radio"
                value="scratch"
                v-model="inputMode"
                :checked="inputMode === 'scratch'"
                @focus="emitSetActiveTab"
              />
              Start From Scratch
            </label>
          </div>
        </div>
      </div>

      <!-- Form -->
      <template>
        <!-- School search bar -->
        <SchoolSearchBar
          v-show="isSchoolSearchMode && !isSnapsheetSelected"
          :isVisible="isSchoolSearchMode"
          :schoolKeyword="schoolKeyword"
          :selectedSchool="selectedSchool"
          v-on="{
            onSchoolSelected: handleSchoolSelected,
            emitSetActiveTab,
            input: handleUpdateSchoolKeyword,
          }"
          @focus="emitSetActiveTab"
        />

        <!--Team Name  -->
        <div class="input-group-column col-12">
          <label>
            Project Title:
            <span class="required">*</span>
          </label>
          <input
            @focus="emitSetActiveTab"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.team_name }"
            placeholder='For example: “Grandview High School” or “Eagles U-18"'
            :value="team_name"
            @input="(e) => $emit('update-team-name', e.target.value)"
            @change="(e) => $emit('update-team-name', e.target.value)"
            ref="teamNameInput"
          />
          <div class="error invalid-feedback" v-if="errors.team_name">
            {{ errors.team_name }}
          </div>
          <span class="form-text text-muted"
            >{{ projectTitleHint }}</span
          >
        </div>

        <!-- Mascot Name -->
        <div v-if="!isSnapsheetSelected" class="input-group-column col-12">
          <label>
            Team Name or Mascot:
            <span class="required">*</span>
          </label>
          <input
            @focus="emitSetActiveTab"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.mascot_name }"
            placeholder="For example: “Grandview” or “Lady Eagles”"
            :value="mascot_name"
            @input="(e) => $emit('update-mascot-name', e.target.value)"
            ref="mascotNameInput"
          />
          <div class="error invalid-feedback" v-if="errors.mascot_name">
            {{ errors.mascot_name }}
          </div>
          <span class="form-text text-muted"
            >We will use this as the Team Name on the garments.</span
          >
        </div>

        <!-- Color Picker -->
        <div
          v-if="!isSnapsheetSelected"
          class="col-12"
          ref="colorPickerContainer"
          id="color-picker-container"
        >
          <div class="row">
            <article
              class="input-group-column flex flex-column col-12 col-sm-4 col-lg-4 align-items-start align-items-sm-center"
            >
              <label class="mr-auto">MAIN COLOR</label>
              <ColorPicker
                :isSnapsheetSelected="isSnapsheetSelected"
                :selected="color_1"
                :field="'color_1'"
                :isActive="opened_pickers.color_1"
                v-on="{ emitSelectColor }"
                @on-open-picker="handleOpenPicker"
              />
              <article class="error invalid-feedback" v-if="errors.color_1">
                {{ errors.color_1 }}
              </article>
            </article>
            <article
              class="input-group-column flex flex-column col-12 col-sm-4 col-lg-4 align-items-start align-items-sm-center"
            >
              <label class="mr-auto">SECOND COLOR</label>
              <ColorPicker
                :isSnapsheetSelected="isSnapsheetSelected"
                :selected="color_2"
                :field="'color_2'"
                :isActive="opened_pickers.color_2"
                v-on="{ emitSelectColor }"
                @on-open-picker="handleOpenPicker"
              />
              <article class="error invalid-feedback" v-if="errors.color_2">
                {{ errors.color_2 }}
              </article>
            </article>
            <div
              class="input-group-column flex flex-column col-12 col-sm-4 col-lg-4 align-items-start align-items-sm-center"
            >
              <label class="mr-auto">THIRD COLOR</label>
              <ColorPicker
                :isSnapsheetSelected="isSnapsheetSelected"
                :selected="color_3"
                :field="'color_3'"
                :isActive="opened_pickers.color_3"
                v-on="{ emitSelectColor }"
                @on-open-picker="handleOpenPicker"
              />
              <div class="error invalid-feedback" v-if="errors.color_3">
                {{ errors.color_3 }}
              </div>
            </div>
          </div>
        </div>

        <!-- Snapsheet Color Picker -->
        <div
          v-if="isSnapsheetSelected"
          class="col-12"
          ref="colorPickerContainer"
          id="color-picker-container"
        >
          <div class="row">
            <article
              class="input-group-column flex flex-column col-12 align-items-start"
            >
              <label class="mr-auto font-weight-bold text-dark">Choose a featured color</label>
              <ColorPicker
                :isSnapsheetSelected="isSnapsheetSelected"
                :selected="color_1"
                :field="'color_1'"
                :isActive="opened_pickers.color_1"
                v-on="{ emitSelectColor }"
                @on-open-picker="handleOpenPicker"
              />
              <span class="form-text text-muted" v-if="isSnapsheetSelected"
              >
                We will use this to customize the garments featured in your Snap Sheet
              </span>
              <article class="error invalid-feedback" v-if="errors.color_1">
                {{ errors.color_1 }}
              </article>
            </article>
          </div>
        </div>

        <p v-if="colorsClash && !isSnapsheetSelected" class="colors-clash col-12">
          For best results, we recommend choosing three unique colors. The third
          color will only be used for light accents - a neutral like white or
          grey is a great option.<span
            title="More info"
            class="colors-clash-info"
            @click="showMoreInfoModal()"
            >MORE INFO</span
          >
        </p>
        <!-- Upload Logo -->
        <div
          class="input-group-column col-12"
          v-if="(!disableLogoTemp && isShowroomSelected) || isSnapsheetSelected"
        >
          <div class="upload-logo" v-if="!logoSrc.length">
            <input
              type="file"
              id="file-1"
              @click="$event.target.value = ''"
              @change="uploadLogo()"
              ref="file"
            />
            <p class="font-weight-bold text-dark">
              {{ customGraphicHint }}
            </p>
            <label for="file-1" class="flex flex-center align-center">
              <img src="~@/assets/icons/upload.svg" alt="upload" />
              <span>
                {{ graphicButtonHint }}
              </span>
            </label>
          </div>
          <div
            id="uploaded-logo"
            class="flex flex-column align-center col"
            v-if="logoSrc"
          >
            <label>TEAM GRAPHIC</label>
            <div class="logo-container">
              <img :src="logoSrc" />
              <i
                class="flaticon-circle"
                id="remove-logo"
                @click="removeLogo()"
                title="Remove logo"
              ></i>
            </div>
          </div>
          <div class="error invalid-feedback" v-if="errors.logo">
            {{ errors.logo }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SchoolSearchBar from "@/components/CreateNewProject/SchoolSearchBar.vue";
import ColorPicker from "@/components/ColorPicker.vue";
import MoreInfoModal from "@/components/CreateNewProject/MoreInfoModal.vue";
export default {
  name: "TeamInformationInputs",
  props: [
    "team_name",
    "mascot_name",
    "color_1",
    "color_2",
    "color_3",
    "opened_pickers",
    "errors",
    "active_tab",
    "isShowroomSelected",
    "isPlaybookSelected",
    "isSnapsheetSelected",
    "showLogoModal",
    "selectedSchool",
    "schoolKeyword",
    "logoSrc",
  ],
  components: { SchoolSearchBar, ColorPicker, MoreInfoModal },
  data() {
    return {
      inputMode: "schoolSearch",
      moreInfoModalOpen: false,
    };
  },
  computed: {
    graphicButtonHint() {
      return !this.isSnapsheetSelected ? 'Upload your team graphic' : 'Upload your graphic';
    },
    customGraphicHint() {
      return !this.isSnapsheetSelected ? 'Custom Team Graphic' : 'Custom Graphic';
    },
    projectTitleHint() {
      return 'We will use as the title of this Project.';
    },
    stepOneTitle() {
      return !this.isSnapsheetSelected ? 'Team Information' : 'Project Information';
    },
    colorsClash() {
      return (
        this.color_1 === this.color_2 ||
        this.color_1 === this.color_3 ||
        this.color_3 === this.color_2
      );
    },
    isScratchMode() {
      return this.inputMode === "scratch";
    },
    isSchoolSearchMode() {
      return this.inputMode === "schoolSearch";
    },
    disableLogoTemp() {
      return false;
    },
  },
  methods: {
    showMoreInfoModal() {
      this.moreInfoModalOpen = true;
    },
    closeMoreInfoModal() {
      this.moreInfoModalOpen = false;
    },
    removeLogo() {
      this.$emit("removeLogo");
    },
    uploadLogo() {
      this.$emit("uploadFile", {
        file: this.$refs.file.files[0],
      });
    },

    handleSchoolSelected(school) {
      this.indicateFilledInputs();
      // this.selectedSchool = school;
      this.$emit("set-school", school);
    },

    handleOpenPicker(e) {
      this.$emit("on-open-picker", e);
      this.$emit("set-active-tab", 1);
    },
    handleUpdateSchoolKeyword(value) {
      this.$emit("update-school-keyword", value);
    },

    emitSelectColor(data) {
      //data is an object -> {field, color}
      this.$emit("update-color", data);
      this.$emit("set-active-tab", 1);
    },

    emitSetActiveTab() {
      this.$emit("set-active-tab", 1);
    },

    indicateFilledInputs() {
      //Ui Improvements
      this.$refs.teamNameInput.classList.add("filled");
      this.$refs.mascotNameInput.classList.add("filled");
      this.$refs.colorPickerContainer.classList.add("filled");

      setTimeout(() => {
        this.$refs.teamNameInput.classList.remove("filled");
        this.$refs.mascotNameInput.classList.remove("filled");
        this.$refs.colorPickerContainer.classList.remove("filled");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.wiz-container {
  margin-bottom: 30px;
  .colors-clash {
    color: red;
    .colors-clash-info {
      padding-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .input-group-column {
    margin-bottom: 3rem;
    label {
      font-size: 1.2rem;
      color: black;
    }
    input {
      transition: background-color 0.5s ease-in;
      border: 1px solid var(--buttonNotSelectedColor);
      &.filled {
        background-color: #5867dd30;
      }
    }
  }

  .selection-section {
    margin-bottom: 10px;
    p {
      margin-bottom: 1.5px;
    }
    .form-control {
      width: auto;
      flex: 1;
      margin-left: 20px;
    }

    .school-scratch-selection-container {
      display: flex;
      justify-content: space-around;
      padding: 0 15px;
      .choose-label {
        width: 42%;
        max-width: 170px;
        input {
          display: none;
        }
      }
      label {
        width: 40%;
        background: white;
        border: 2px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0 5px -1px #0000005e;
        color: black;
        padding: 5px;
        text-align: center;
        text-transform: uppercase;
        white-space: break-spaces;

        &.active {
          font-weight: 700;
        }

        &.active.showroom {
          border: 2px solid var(--showroomColor);
          span {
            background-color: var(--showroomColor);
          }
        }
        &.active.playbook {
          border: 2px solid var(--playbookColor);
          span {
            background-color: var(--playbookColor);
          }
        }
        &.active.snapsheet {
          border: 2px solid var(--snapsheetColor);
          span {
            background-color: var(--snapsheetColor);
          }
        }

        &.disabled {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }

  .color-picker-row {
    display: flex;
    align-items: flex-start;
    transition: background-color 0.5s ease-in;

    &.filled {
      background-color: #5867dd30;
    }

    .input-group-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.wiz-last-col {
  display: flex;
  flex-direction: column;
}
.wiz-submit-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px;
  .loading-spinner {
    margin-right: 0 50px;
  }
}
.wiz-nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-basis: auto;
  margin-bottom: 2.3rem;
  font-weight: 500;
  font-size: 1.15rem;
  span {
    width: 2.7rem;
    height: 2.7rem;
    font-size: 1.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    background-color: #d2d0e0;
    color: #ffffff;
  }
  &.active.showroom {
    color: var(--showroomColor);
    span {
      background-color: var(--showroomColor);
    }
  }
  &.active.playbook {
    color: var(--playbookColor);
    span {
      background-color: var(--playbookColor);
    }
  }
  &.active.snapsheet {
    color: var(--snapsheetColor);
    span {
      background-color: var(--snapsheetColor);
    }
  }
}

#color-picker-container {
  label {
    color: var(--buttonNotSelectedColor);
  }
}
.text-muted {
  margin: 10px 0;
}
</style>