import Vue from 'vue'
import VueRouter from 'vue-router'
import NewProject from '../views/CreateNewProject.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'New Project',
    component: NewProject,
    meta:{
      title:'BSN Playbooks'
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
