<template>
  <div id="more-info-modal">
    <div class="content">
      <p>
        We highly recommend using three distinct colors for best results.
      </p>
      <p>
        We use color 3 for small accents, and as an outline for team names and numbers as shown in the examples below.
      </p>
      <div class="images">
        <img src="~@/assets/coloring-info.jpg">        
      </div>
      <div class="row controls">
        <button type="button" class="btn black-btn" @click="close()">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MoreInfoModal",
  methods: {
    close() {
      this.$emit("closeMoreInfoModal");
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  div#more-info-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 600px;
      max-width: 95%;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 25px 50px;
      @media screen and (max-width:1024px){
         padding: 5px 10px; 
      }
      p {
        font-weight: bold;
        color: black;
      }
      .images {
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 100%;
          height: auto;
        }
      }
      .controls {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .btn {
          color: #000;
          font-size: 12px;
          line-height: 13px;
          letter-spacing: 0.91px;
          padding: 8px 25px;
          border-radius: 109px;
          border: 1px solid #000;
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          &:disabled {
            opacity: 0.25;
            cursor: initial;
          }
          &.white-btn {
            background: #fff;
            &:hover {
              color: #fff;
              background: #000;
            }
          }
          &.black-btn {
            color: #fff;
            background-color: #000;
            border-color: #fff;
            &:hover {
              color: #000;
              background-color: #fff;
              border-color: #000;
            }
          }
        }
      }
    }
  }
}
</style>