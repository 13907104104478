<template>
  <div class="team" v-if="!loading" @click="closeOpenedPicker">
    <div class="kt-portlet" id="company-form">
      <div v-if="formEnabled" class="kt-portlet__body">
        <div
          :class="{
            showroom: isShowroomSelected,
            playbook: isPlaybookSelected,
            snapsheet: isSnapsheetSelected,
          }"
          class="row no-gutters title-container mb-4 align-items-end"
        >
          <div class="col-12 col-lg-3 m-auto">
            <h1 class="text-uppercase">
              {{ formTitle }}
            </h1>
          </div>
          <div class="col-12 col-lg-9 py-2 m-0">
            <p class="m-0">
              {{ formSubtitle }}

              <a
                class="example-link"
                v-if="exampleLink"
                :href="exampleLink"
                target="_blank"
              >
                CLICK HERE to view an example</a
              >
            </p>
          </div>
        </div>

        <!--begin::Form-->
        <div class="form-group row" :class="{ validated: has_errors }">
          <!-- Inputs & Color picker -->
          <TeamInformationInputs
            :team_name="team_name"
            :mascot_name="mascot_name"
            :color_1="color_1"
            :color_2="color_2"
            :color_3="color_3"
            :selectedColor="color_1"
            :index="'color_1'"
            :opened_pickers="opened_pickers"
            :errors="errors"
            :active_tab="active_tab"
            :showLogoModal="showLogoModal"
            :logoSrc="logoSrc"
            :selectedSchool="selectedSchool"
            :schoolKeyword="schoolKeyword"
            @update-school-keyword="handleUpdateSchoolKeyword"
            @update-color="handleSelectColor"
            @update-mascot-name="handleUpdateMascotName"
            @update-team-name="handleUpdateTeamName"
            @set-school="handleSetSchool"
            @set-active-tab="handleSetActiveTab"
            @on-open-picker="handleOpenedPicker"
            @uploadFile="uploadFile"
            @removeLogo="removeLogo"
            :isShowroomSelected="isShowroomSelected"
            :isPlaybookSelected="isPlaybookSelected"
            :isSnapsheetSelected="isSnapsheetSelected"
          />
          <LogoModal
            v-if="showLogoModal"
            @close="showLogoModal = false"
            :fileUploaded="fileUploaded"
            :fileName="fileName"
            :fileSize="fileSize"
            :fileExtension="fileExtension"
            :fileOriginal="fileOriginal"
            :isVector="isVector"
            v-on="{ reValidateForm }"
          ></LogoModal>
          <!-- PRODUCT SELECTION -->

          <!-- Showroom Sports Cards -->
          <template v-if="isShowroomSelected">
            <div class="col-lg-4">
              <div class="wiz-container row 1">
                <div
                  class="wiz-nav-item col-12"
                  :class="{
                    active: active_tab === 2,
                    showroom: isShowroomSelected,
                    playbook: isPlaybookSelected,
                    snapsheet: isSnapsheetSelected,
                  }"
                >
                  <span>2</span>
                  <p class="form-section-title m-0">Product Selection</p>
                </div>

                <div
                  v-if="showroomCampaigns.length"
                  class="selection-section col-12"
                >
                  <div class="flex align-center">
                    <div class="flex flex-wrap sports-container">
                      <div class="form-group m-0">
                        <div class="special-campaigns">
                          <!-- Radio Buttons -->
                          <label
                            class="kt-radio col-sm-6 m-0"
                            v-for="campaign in showroomCampaigns"
                            :key="campaign.id"
                            @click="
                              campaign.id !== 4 && selectCampaign(campaign)
                            "
                            :class="{ disabled: campaign.id === 4 }"
                          >
                            <input
                              type="radio"
                              :checked="selectedCampaignId === campaign.id"
                              @focus="handleSetActiveTab(2)"
                            />
                            {{ campaign.name }}
                            <template v-if="campaign.id === 4">
                              (Coming soon...)
                            </template>

                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="showroomSports && showroomSports.length"
                  class="selection-section"
                >
                  <div
                    class="flex flex-wrap justify-content-center sports-container"
                  >
                    <SportCard
                      v-for="(sport, index) in showroomSports"
                      v-bind:key="index"
                      :id="sport.id"
                      :sport="sport"
                      :type="'sport'"
                      :is_active="showroomSelectedSports.indexOf(sport.id) > -1"
                      :typeOfCard="'showroom'"
                      v-model="showroomSelectedSports"
                      :value="sport"
                      v-on="{ selectSport: handleShowroomSelectedSports }"
                    />
                  </div>
                </div>

                <div v-else class="selection-section">
                  Current Campaign does not have any catalogs
                </div>

                <!-- Error message -->
                <div
                  class="error invalid-feedback"
                  v-if="errors.showroomSelectedSports"
                >
                  {{ errors.showroomSelectedSports }}
                </div>
              </div>
            </div>
          </template>
          <!--  -->
          <!-- Playbook Sports cards -->
          <template v-if="isPlaybookSelected">
            <div class="col-lg-4">
              <div class="wiz-container row 1">
                <div
                  class="wiz-nav-item col-12"
                  :class="{
                    active: active_tab === 2,
                    showroom: isShowroomSelected,
                    playbook: isPlaybookSelected,
                    snapsheet: isSnapsheetSelected,
                  }"
                >
                  <span>2</span>
                  <p class="form-section-title m-0">Template Selection</p>
                </div>

                <!-- Begin::Sport Cards -->

                <div
                  v-if="playbookCampaigns && playbookCampaigns.length"
                  class="selection-section col-12"
                >
                  <div
                    class="flex flex-wrap justify-content-center sports-container"
                  >
                    <SportCard
                      v-bind:key="index"
                      :id="sport.id"
                      :sport="sport"
                      :type="'sport'"
                      :is_active="playbookSelectedSports.indexOf(sport.id) > -1"
                      :typeOfCard="'playbook'"
                      v-for="(sport, index) in playbookCampaigns"
                      v-on="{ selectSport: handlePlaybookSelectedSports }"
                    />
                  </div>
                </div>
                <!-- End::Sport Cards -->

                <div v-else class="selection-section">
                  Current Campaign does not have any catalogs
                </div>

                <div
                  class="error invalid-feedback"
                  v-if="errors.playbookSelectedSports"
                >
                  {{ errors.playbookSelectedSports }}
                </div>
              </div>
            </div>
          </template>

          <!-- Snapsheet Layout cards -->
          <template v-if="isSnapsheetSelected">
            <div class="col-lg-4">
              <div class="wiz-container row 1">
                <div
                  class="wiz-nav-item col-12"
                  :class="{
                    active: active_tab === 2,
                    snapsheet: isSnapsheetSelected,
                  }"
                >
                  <span>2</span>
                  <p class="form-section-title m-0">Layout selection</p>
                </div>

                <!-- Radio buttons -->
                <div class="selection-section col-12 mb-4">
                  <div
                    class="row m-0 school-scratch-selection-container form-group row align-items-center"
                  >
                    <!-- Form Container -->

                    <!-- Select radio Choose Categories -->
                    <label
                      class="kt-radio m-0 text-dark choose-category-label"
                      :class="{
                        active: snapsheetLayoutMode === 'chooseCategories',
                        snapsheet: isSnapsheetSelected,
                      }"
                    >
                      Choose Categories

                      <input
                        :disabled="projectLinkUrl && projectLinkUrl.length"
                        type="radio"
                        value="chooseCategories"
                        v-model="snapsheetLayoutMode"
                        :checked="snapsheetLayoutMode === 'chooseCategories'"
                        @focus="handleSetActiveTab(2)"
                      />
                    </label>

                    <span class="col-1 m-0 text-center">Or</span>

                    <!-- Select radio Start from scratch -->
                    <label
                      class="kt-radio choose-category-label m-0"
                      :class="{
                        active: snapsheetLayoutMode === 'startFromScratch',
                        snapsheet: isSnapsheetSelected,
                      }"
                    >
                      <input
                        :disabled="projectLinkUrl && projectLinkUrl.length"
                        type="radio"
                        value="startFromScratch"
                        v-model="snapsheetLayoutMode"
                        :checked="snapsheetLayoutMode === 'startFromScratch'"
                        @focus="handleSetActiveTab(2)"
                      />
                      Start From Scratch
                    </label>
                  </div>
                </div>

                <!-- Begin::Categories cards-->
                <template v-if="snapsheetLayoutMode === 'startFromScratch'">
                  <SnapsheetLayoutDropdown
                    :layoutsValues="snapsheetLayoutsValues"
                    :selectedLayout="snapsheetSelectedLayoutId"
                    :errors="errors"
                    v-on="{
                      selectLayout: handleSnapsheetSelectedLayoutId,
                      setActiveTab: handleSetActiveTab,
                    }"
                  />
                </template>
                <!-- End::Categories cards-->

                <template v-if="snapsheetLayoutMode === 'chooseCategories'">
                  <SnapsheetTemplateCards
                    v-on="{ setActiveSnapsheetTemplate }"
                    :activeTemplates="snapsheetActiveTemplates"
                  />
                </template>

                <p
                  v-if="snapsheetLayoutMode === 'startFromScratch'"
                  class="text-muted col-12"
                >
                  Choose how many products you want to feature, you will be able
                  to select specific products after you create your Snap Sheet.
                </p>
                <p
                  v-if="snapsheetLayoutMode === 'startFromScratch'"
                  class="text-muted col-12"
                >
                  Need more than 6? We recommend you use the “Choose Categories“
                  features instead.
                </p>

                <p
                  v-else-if="snapsheetLayoutMode === 'chooseCategories'"
                  class="text-muted col-12"
                >
                  Choose as many categories as you want to feature, we will
                  feature the best selling styles for each category.
                </p>

                <div
                  class="error invalid-feedback"
                  v-if="errors.snapsheetSelectedLayoutId"
                >
                  {{ errors.snapsheetSelectedLayoutId }}
                </div>
              </div>
            </div>
          </template>

          <!-- end:PRODUCT SELECTION -->

          <!-- SalesReps and Submit Button -->
          <div class="col-lg-4" id="rep-info">
            <div class="wiz-container row">
              <!-- Title -->
              <div
                class="wiz-nav-item col-12"
                :class="{
                  active: active_tab === 3,
                  showroom: isShowroomSelected,
                  playbook: isPlaybookSelected,
                  snapsheet: isSnapsheetSelected,
                }"
              >
                <span>3</span>
                <p class="form-section-title m-0">Sales Rep Information</p>
              </div>

              <!-- Sales reps inputs -->
              <div class="form-group col-12">
                <div class="input-group-column col-12">
                  <label class="text-black" for="school">
                    Name:<span class="required">*</span></label
                  >
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Name..."
                      v-model="rep_info_1"
                    />
                  </div>
                  <div class="error invalid-feedback" v-if="errors.rep_info_1">
                    {{ errors.rep_info_1 }}
                  </div>
                </div>
                <div class="input-group-column col-12">
                  <label class="text-black" for="school">
                    Email:<span class="required">*</span></label
                  >
                  <div class="kt-input-icon kt-input-icon--left email-input">
                    <input
                      class="form-control mr-1"
                      type="text"
                      placeholder="Email..."
                      v-model="rep_email"
                    />

                    <span>@bsnsports.com</span>
                  </div>
                  <div class="error invalid-feedback" v-if="errors.rep_email">
                    {{ errors.rep_email }}
                  </div>
                </div>
                <div class="input-group-column col-12">
                  <label class="text-black" for="school">
                    Phone:<span class="required">*</span></label
                  >
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Phone..."
                      v-model="rep_info_3"
                    />
                  </div>
                  <div class="error invalid-feedback" v-if="errors.rep_info_1">
                    {{ errors.rep_info_1 }}
                  </div>
                </div>
              </div>

              <!-- Submits buttons -->
              <!-- Submit Playbook -->
              <div class="row wiz-submit-container">
                <button
                  v-if="!formLoading"
                  type="button"
                  class="btn btn-tall btn-primary col-12 m-auto m-lg-0"
                  :class="{
                    showroom: isShowroomSelected,
                    playbook: isPlaybookSelected,
                  }"
                  @click="submitPlaybook()"
                >
                  Create Playbook
                </button>
                <div class="loading-spinner" v-else>
                  <div class="flex flex-center">
                    <div
                      class="kt-spinner kt-spinner--md kt-spinner--info"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Iframe for projects -->
      <div
        class="row project-sucess"
        id="project-sucess"
        v-if="
          projectLink &&
            projectLink.type &&
            ((isPlaybookSelected && projectLink.type == 'playbook') ||
              (isShowroomSelected && projectLink.type == 'showroom') ||
              (isSnapsheetSelected && projectLink.type == 'snapsheet'))
        "
      >
        <p class="create-another-project ml-auto p-2">
          Need to create another {{ projectLink.type }}?
          <span @click="resetForm">Click here</span>
        </p>
        <h5
          class="col-12 text-center text-uppercase"
          :class="{
            showroom: this.projectLink.type === 'showroom',
            playbook: this.projectLink.type === 'playbook',
            snapsheet: this.projectLink.type === 'snapsheet',
          }"
        >
          {{ projectReadyTitle }}
        </h5>
        <div class="divider"></div>
      </div>

      <div class="row" v-show="projectLinkCreated">
        <div class="col col-sm-8 m-auto">
          <div class="form-group">
            <div class="input-group" style="align-items: flex-end">
              <label>Your Project Link:</label>
              <input
                type="text"
                class="form-control"
                readonly
                id="copy-project-link"
                v-model="projectLinkUrl"
                @click="openTeamLink()"
              />
              <div class="input-group-append">
                <a href="javascript:;" @click="copyToClipboard()" class="btn"
                  ><i class="la la-copy"></i>Copy to clipboard</a
                >
              </div>
            </div>
            <div class="input-group" id="copied-msg" v-if="copiedToClipboard">
              <small>Successfully copied to clipboard!</small>
            </div>
          </div>
          <div class="project-link-information" v-if="!isSnapsheetSelected">
            <p>
              Let your team make the call: Send the link above to your coach.
              They can choose their gear and customize it and before sending
              back to you in form of a "Quote Request" that will go straight to
              your email.
            </p>
            <p>
              Finish it off: If you are ready to pick designs and send it to
              Order my Gear to create the store, please continue below. You'll
              see the option to "Request an OMG Store" once you've made your
              selections.
            </p>
            <p>
              Have questions?
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=b6QTLKzOf6g"
                >Check out the overview here.</a
              >
            </p>
          </div>
          <div
            v-else-if="this.snapsheetLayoutMode === 'chooseCategories'"
            class="project-link-information"
          >
            <p>
              We've featured our recommended products for every category. If you
              need to change anything, hover your mouse over any product to see
              options.
            </p>
            <p>
              Edit Decorations will let you swap the Custom Graphic or move its
              location.
            </p>
            <p>
              Edit Item Details will let you customize item pricing, change the
              featured color or swap any product.
            </p>
          </div>
          <div v-else class="project-link-information">
            <p>
              Please use the "Add Product" buttons to find your preferred
              product by name or style number.
            </p>
          </div>
        </div>
      </div>

      <iframe
        id="dealer-mode"
        v-if="projectLinkCreated"
        :src="
          isSnapsheetSelected
            ? projectLinkUrl + '?passcode=' + dealer.passcode
            : projectLinkUrl + '?dealer_mode=1'
        "
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import ColorPicker from "@/components/ColorPicker.vue";
import SportCard from "@/components/SportCard.vue";
import SnapsheetLayoutDropdown from "@/components/SnapsheetLayoutDropdown.vue";
import SnapsheetTemplateCards from "@/components/SnapsheetTemplateCards.vue";
import TeamInformationInputs from "@/components/CreateNewProject/TeamInformationInputs.vue";
import LogoModal from "@/components/LogoModal.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "CreateNewProjectForm",
  components: {
    TeamInformationInputs,
    SportCard,
    SnapsheetLayoutDropdown,
    LogoModal,
    SnapsheetTemplateCards,
  },

  props: [
    "school",
    "reps",
    "typeOfProjectSelected",
    "showroomCampaigns",
    "playbookCampaigns",
  ],

  data() {
    return {
      //Form UI
      active_tab: 1,
      selectedSchool: "",
      schoolKeyword: "",
      opened_pickers: {
        color_1: false,
        color_2: false,
        color_3: false,
      },
      snapsheetLayoutsValues: [1, 2, 3, 4, 5, 6],
      snapsheetLayoutMode: "chooseCategories",
      snapsheetActiveTemplates: [],
      errors: [],

      //Form Inputs
      team_name: "",
      mascot_name: "",
      color_1: {},
      color_2: {},
      color_3: {},
      playbookSelectedSports: [],
      showroomSelectedSports: [],
      snapsheetSelectedLayoutId: 0,
      rep_info_1: "",
      rep_email: "",
      rep_info_3: "",
      //logo upload
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",
      logoSrc: "",
      isVector: false,
      showLogoModal: false,

      copiedToClipboard: false,
      tempHide: true,
      loading: false,
      formLoading: false,
      projectLink: "",

      selectedCampaignId: [],
      selected_apparel: [],
      campaignTemplates: [],
      campaignSelectedTemplate: false,

      //override
      overridePricing: false,
      display_msrp: 1,
      complimentary_msg: 1,
    };
  },

  computed: {
    ...mapState(["dealer", "settings", "snapsheetColorsIds"]),
    ...mapGetters(["getColorByName", "getColorById"]),
    formEnabled() {
      return (
        this.isShowroomSelected ||
        this.playbookFormEnabled ||
        this.isSnapsheetSelected
      );
    },

    playbookFormEnabled() {
      return this.isPlaybookSelected && this.dealer.logo;
    },

    formTitle() {
      return this.isShowroomSelected
        ? "Create a new Showroom"
        : this.isPlaybookSelected
        ? "Create a new Playbook"
        : "Create a new snap sheet";
    },

    formSubtitle() {
      return this.isShowroomSelected
        ? "Create a fully interactive team mini-sites with your branding, let your customer make and customize their selections to create a Locker. Once they Request a Quote it'll go straight to your inbox."
        : this.isPlaybookSelected
        ? "Create a custom team catalog for any sport and activity. Features your branding, personalized for your customer's team colors"
        : 'Create a custom sale sheet, featuring top sellers for any category - or use the "Start From Scratch" Feature to pick your own products. Customize colors & graphics as needed.';
    },

    exampleLink() {
      return this.isShowroomSelected
        ? "https://customgameplan.com/IGN001/showroom/401/falcons-football-team"
        : this.isPlaybookSelected
        ? "https://s3.amazonaws.com/fsg-playbooks.foundersportgroup.com/1938_Osceola-Junior-Senior-High.pdf"
        : "";
    },

    isShowroomSelected() {
      return this.typeOfProjectSelected === "showroom";
    },

    isPlaybookSelected() {
      return this.typeOfProjectSelected === "playbook";
    },

    isSnapsheetSelected() {
      return this.typeOfProjectSelected === "snapsheet";
    },

    showroomSports() {
      if (this.selectedCampaignId) {
        for (let index = 0; index < this.showroomCampaigns.length; index++) {
          const campaign = this.showroomCampaigns[index];
          if (campaign.id === this.selectedCampaignId) {
            return campaign.catalogs;
          }
        }
      }
      return [];
    },

    has_errors() {
      return Object.keys(this.errors).length > 0;
    },
    colors() {
      return this.dealer.colors;
    },

    projectLinkCreated() {
      return this.projectLink && this.projectLink.url;
    },

    projectLinkUrl() {
      return this.projectLink.url;
    },

    projectReadyTitle() {
      return this.projectLink.type === "showroom"
        ? "Your showroom is ready! "
        : this.projectLink.type === "playbook"
        ? "Your playbook is ready!"
        : this.snapsheetLayoutMode === "chooseCategories"
        ? "Your snap sheet is ready!"
        : "YOUR SNAP SHEET IS ALMOST READY!";
    },
  },

  methods: {
    ...mapActions(["addTeamLink", "addNewSalesRep"]),

    //UI Handlers
    setActiveSnapsheetTemplate(template) {
      if (this.snapsheetActiveTemplates.indexOf(template) >= 0) {
        this.snapsheetActiveTemplates = this.snapsheetActiveTemplates.filter(
          (activeTemplate) => {
            return activeTemplate !== template;
          }
        );
      } else {
        this.snapsheetActiveTemplates.push(template);
      }
    },
    handleSetActiveTab(value) {
      this.active_tab = value;
    },
    handleOpenedPicker(openedPicker) {
      let pickers = ["color_1", "color_2", "color_3"];
      for (let key = 0; key < pickers.length; key++) {
        const color_key = pickers[key];
        if (color_key !== openedPicker) {
          this.opened_pickers[color_key] = false;
        } else {
          this.opened_pickers[color_key] = !this.opened_pickers[color_key];
        }
      }
    },

    closeOpenedPicker() {
      Object.keys(this.opened_pickers).map((pickerKey) => {
        this.opened_pickers[pickerKey] = false;
      });
    },
    //---

    //Handle form updates
    handleSetSchool(school) {
      this.selectedSchool = school;
      this.handleUpdateSchoolKeyword(school.name);
      this.handleUpdateTeamName(school.name);
      this.handleUpdateMascotName(school.mascot);
      this.handleSelectColor({
        field: "color_1",
        color: this.getColorByName(school.color_1),
      });
      this.handleSelectColor({
        field: "color_2",
        color: this.getColorByName(school.color_2),
      });
      this.handleSelectColor({
        field: "color_3",
        color: this.getColorByName(school.color_3),
      });
    },
    handleUpdateSchoolKeyword(value) {
      this.schoolKeyword = value;
    },
    handleUpdateTeamName(value) {
      this.team_name = value;
      this.reValidateForm();
    },
    handleUpdateMascotName(value) {
      this.mascot_name = value;
      this.reValidateForm();
    },
    handleSelectColor({ field, color }) {
      this[field] = color;
      this.opened_pickers = {
        color_1: false,
        color_2: false,
        color_3: false,
      };
      this.reValidateForm();
    },
    handlePlaybookSelectedSports(sport_id) {
      // this.playbookSelectedSports.push(sport_id);
      this.playbookSelectedSports = [sport_id];
      this.active_tab = 2;
      this.reValidateForm();
    },
    handleShowroomSelectedSports(sport_id) {
      if (this.showroomSelectedSports.includes(sport_id)) {
        this.showroomSelectedSports = this.showroomSelectedSports.filter(
          (sport) => sport !== sport_id
        );
      } else {
        this.showroomSelectedSports.push(sport_id);
      }
      // this.showroomSelectedSports = [sport_id];
      this.active_tab = 2;
      this.reValidateForm();
    },
    handleSnapsheetSelectedLayoutId(layoutValue) {
      this.snapsheetSelectedLayoutId = Number(layoutValue);
      this.active_tab = 2;
    },

    handleSelectedRep() {
      this.selected_rep = false;
      for (let index = 0; index < this.reps.length; index++) {
        if (this.reps[index].id === this.selected_rep_id) {
          this.selected_rep = this.reps[index];
        }
      }

      this.reValidateForm();
    },

    resetForm() {
      this.formLoading = false;
      this.selectedSchool = "";
      this.handleUpdateSchoolKeyword("");
      this.team_name = "";
      this.mascot_name = "";
      this.color_1 = this.$store.state.dealer.colors[14]; //todo set default as constants
      this.color_2 = this.$store.state.dealer.colors[8];
      this.color_3 = this.$store.state.dealer.colors[20];
      this.opened_pickers = {
        color_1: false,
        color_2: false,
        color_3: false,
      };
      this.selected_rep_id = 0;
      this.selected_rep = {};
      this.showroomSelectedSports = [];
      this.playbookSelectedSports = [];
      this.snapsheetSelectedLayoutId = 0;
      this.projectLink = "";

      window.scrollTo({
        top: document.getElementById("company-form").offsetTop,
        behavior: "smooth",
      });
    },

    //---
    transformEmail() {
      //Find any '@'
      const atIndex = this.rep_email.indexOf("@");

      if (atIndex > -1) this.rep_email = this.rep_email.slice(0, atIndex);
    },
    //Post Forms
    async submitShowroom() {
      if (!this.formLoading && this.checkForm()) {
        this.formLoading = true;

        let params = {
          color_1: this.color_1.id,
          color_2: this.color_2.id,
          color_3: this.color_3.id,
          catalogs: this.showroomSelectedSports.length
            ? this.showroomSelectedSports
            : [this.campaignSelectedTemplate],
          title: this.team_name,
          team_name: this.mascot_name,
        };

        if (Object.keys(this.selected_rep).length) {
          params.rep = this.selected_rep.id;
        }

        if (this.logoSrc.length) {
          params.logo_converted = this.logoSrc;
          params.logo_original = this.fileOriginal;
        }

        if (this.overridePricing) {
          params.overridePricing = this.overridePricing;
          params.display_msrp = this.display_msrp;
          params.complimentary_msg = this.complimentary_msg;
        }

        if (this.checkForm()) {
          await axios
            .post(this.settings.endpoint + "api/v2/team-link", params, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.dealer.api_token,
              },
            })
            .then((response) => {
              this.addTeamLink(response.data);
              this.$store.dispatch("showToast", {
                txt: 'Created Project for "' + this.team_name + '"',
              });
              //Show Iframe using the team link code
              const url =
                this.settings.builder +
                this.dealer.rhino_code +
                "/showroom/" +
                response.data.id +
                "/" +
                response.data.link.replace("_" + response.data.id, "");
              const type = "showroom";
              this.setProjectLink(url, type);
              //reset form data
              // this.resetForm();
              setTimeout(() => {
                window.scrollTo({
                  top: document.getElementById("project-sucess").offsetTop,
                  behavior: "smooth",
                });
              }, 250);
            })
            .catch((e) => {
              this.$store.dispatch("showToast", {
                txt: e,
                type: "error",
              });
            })
            .finally(() => {
              this.formLoading = false;
            });
        }
      }
    },

    async submitPlaybook() {
      if (!this.formLoading && this.checkForm()) {
        this.formLoading = true;

        //Check '@' in the rep email
        this.transformEmail();

        let params = {
          "color-picker-1": this.color_1.playbook_match_color_id,
          "color-picker-2": this.color_2.playbook_match_color_id,
          "color-picker-3": this.color_3.playbook_match_color_id,
          dealer_code: this.dealer.fsg_code,
          dealer_logo: this.dealer.logo,
          "team-name": this.mascot_name,
          template: this.playbookSelectedSports[0],
          title: this.team_name,
          rep_info_1: this.rep_info_1,
          rep_info_2: this.rep_email + "@bsnsports.com",
          rep_info_3: this.rep_info_3,
        };

        await axios
          .post(this.settings.playbookEndpoint + "playbook-showroom", params, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            this.$store.dispatch("showToast", {
              txt: "Your playbook request has been received",
            });
            //Show Iframe using the playbook link code
            const url = "";
            const type = "playbook";
            this.setProjectLink(url, type);
            //Reset form data ---
            // this.resetForm();
          })
          .catch((e) => {
            this.$store.dispatch("showToast", {
              txt: e,
              type: "error",
            });
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
    },

    async submitSnapsheet() {
      if (!this.formLoading && this.checkForm()) {
        let url = this.settings.endpoint + "api/snapsheets/snapsheet",
          params = {};
        this.formLoading = true;

        if (this.snapsheetLayoutMode === "chooseCategories") {
          //start from template
          params = {
            title: this.team_name,
            color_1: this.color_1.id,
            //collection: this.snapsheetActiveTemplate,
            collections: this.snapsheetActiveTemplates,
            rep_id: this.selected_rep.id,
          };
        } else {
          //start from scratch
          params = {
            title: this.team_name,
            color_1: this.color_1.id,
            layout_id: this.snapsheetSelectedLayoutId,
            rep_id: this.selected_rep.id,
          };
        }

        if (this.logoSrc.length) {
          params.logo_converted = this.logoSrc;
          params.logo_original = this.fileOriginal;
        }

        await axios
          .post(url, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.dealer.api_token,
            },
          })
          .then((response) => {
            this.$store.dispatch("showToast", {
              txt: 'Snapsheet Created for "' + this.team_name + '"',
            });
            //Show Iframe using the team link code

            const url = `${this.settings.snapsheetEndpoint}snapsheets/${this.dealer.rhino_code}/${response.data.id}`;

            const type = "snapsheet";

            this.setProjectLink(url, type);

            // this.resetForm();
            setTimeout(() => {
              window.scrollTo({
                top: document.getElementById("project-sucess").offsetTop,
                behavior: "smooth",
              });
            }, 250);
          })
          .catch((e) => {
            this.$store.dispatch("showToast", {
              txt: e,
              type: "error",
            });
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
    },

    //---
    //Set project link
    setProjectLink(url, type) {
      this.projectLink = { url, type };
    },

    openTeamLink() {
      let win = window.open(this.projectLink.url, "_blank");
      win.focus();
    },
    copyToClipboard() {
      this.copiedToClipboard = false;
      let copyText = document.getElementById("copy-project-link");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      this.copiedToClipboard = true;
    },
    postModal(data) {
      let params = {
        name: data.modalName,
        job_title: data.modalJobTitle,
        email: data.modalEmail,
        phone: data.modalPhone,
        _method: "post",
      };

      this.addNewSalesRep(params).then(this.getAllReps());
    },
    selectCampaign(campaign) {
      this.selectedCampaignId = campaign.id;
    },
    getAllReps() {
      this.loading = true;
      //get all sync orders
      axios
        .get(this.settings.endpoint + "api/v2/reps", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.dealer.api_token,
          },
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.$store.dispatch("showToast", {
            txt: error,
            type: "error",
          });
          this.loading = false;
        });
    },

    checkForm(withToast = true) {
      this.errors = {};

      if (!this.playbookSelectedSports.length) {
        if (!this.campaignSelectedTemplate) {
          this.errors.playbookSelectedSports = "Select at least one template.";
        }
      }

      if (!this.mascot_name.length) {
        this.errors.mascot_name = "Mascot is required.";
      } else {
        if (this.mascot_name.length > 100) {
          this.errors.mascot_name =
            "Mascot name cannot be longer than 100 characters.";
        }
      }

      if (!this.team_name.length) {
        this.errors.team_name = "Name is required.";
      } else {
        if (this.team_name.length > 100) {
          this.errors.team_name = "Name cannot be longer than 100 characters.";
        }
      }

      if (!this.rep_info_1.length) {
        this.errors.rep_info_1 = "Rep name is required.";
      } else {
        if (this.rep_info_1.length > 50) {
          this.errors.rep_info_1 =
            "Rep name cannot be longer than 50 characters.";
        }
      }

      if (!this.rep_email.length) {
        this.errors.rep_email = "Rep email is required.";
      } else {
        if (this.rep_email.length > 100) {
          this.errors.rep_email =
            "Rep email cannot be longer than 100 characters.";
        }
      }

      if (!this.rep_info_3.length) {
        this.errors.rep_info_3 = "Rep phone is required.";
      } else {
        if (this.rep_info_3.length > 50) {
          this.errors.rep_info_3 =
            "Rep phone cannot be longer than 50 characters.";
        }
      }

      if (!Object.keys(this.color_1).length) {
        this.errors.color_1 = "Main Color is required.";
      }
      if (!Object.keys(this.color_2).length) {
        this.errors.color_2 = "Second Color is required.";
      }
      if (!Object.keys(this.color_3).length) {
        this.errors.color_3 = "Third Color is required.";
      }

      if (!Object.keys(this.errors).length) {
        return true;
      }

      if (withToast) {
        this.$store.dispatch("showToast", {
          txt: "Please verify your information is correct",
          type: "error",
        });
      }
      return false;
    },
    removeLogo() {
      this.logoSrc = "";
    },

    uploadFile(data) {
      this.showLogoModal = true;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = data.file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];

      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post(this.settings.endpoint + "api/v2/team-link-logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.dealer.api_token,
          },
        })
        .then((response) => {
          this.fileUploaded = response.data.content;
          this.fileOriginal = response.data.url;
        })
        .catch(() => {
          this.$store.dispatch("showToast", {
            txt: "Invalid image",
            type: "error",
          });
          this.showLogoModal = false;
          this.logoSrc = "";
          this.file = null;
        });
    },

    reValidateForm() {
      if (this.has_errors) {
        this.checkForm(false);
      }
    },

    rep_names() {
      let names = [],
        reps = this.dealer.reps;
      for (let index = 0; index < reps.length; index++) {
        names.push(reps[index].name);
      }
      return names;
    },
  },

  watch: {
    isSnapsheetSelected: function() {
      //If the color_1 doesn't belong to the snapsheets color options, set it to Royal
      if (this.isSnapsheetSelected) {
        if (
          !this.snapsheetColorsIds.some(
            (spColorId) => spColorId == this.color_1.id
          )
        )
          this.handleSelectColor({
            field: "color_1",
            color: this.getColorById(19),
          });
      }
    },
  },

  mounted() {
    this.color_1 = this.dealer.colors[14];
    this.color_2 = this.dealer.colors[8];
    this.color_3 = this.dealer.colors[20];
    this.selectedCampaignId = this.showroomCampaigns[0].id;
  },
};
</script>
<style lang="scss">
:root {
  --showroomColor: #0a80ed;
  --showroomColorHover: #0872d4;
  --showroomColorActive: #2b92f3;
  --playbookColor: #059c37;
  --playbookColorHover: #2faa4c;
  --playbookColorActive: #06c044;
  --snapsheetColor: #d5000b;
  --snapsheetColorHover: #bd060f;
  --snapsheetColorActive: #dd1e28;
  --buttonNotSelectedColor: #707070;
}

#company-form {
  .title-container {
    h1 {
      color: black;
      font-size: 1.5rem;
      font-weight: 700;
    }

    p {
      color: #838383;
      font-weight: 300;
    }

    .example-link {
      margin-left: 0.2rem;
      color: #000000;
      text-decoration: underline;
    }

    &.showroom {
      border-bottom: 4px solid var(--showroomColor);
    }
    &.playbook {
      border-bottom: 4px solid var(--playbookColor);
    }
    &.snapsheet {
      border-bottom: 4px solid var(--snapsheetColor);
    }
  }

  .wiz-container {
    margin-bottom: 30px;
    .team-link-add-user {
      margin-left: 5px;
      display: flex;
      button {
        display: flex;
        align-items: center;
        font-family: "SlateStd" !important;
        i {
          color: white;
        }
      }

      .btn.showroom {
        background-color: var(--showroomColor);
        border: 1px solid var(--showroomColor);
        &:hover {
          background: var(--showroomColorHover);
        }
        &:active {
          background: var(--showroomColorActive);
        }
      }
      .btn.playbook {
        background-color: var(--playbookColor);
        border: 1px solid var(--playbookColor);
        &:hover {
          background: var(--playbookColorHover);
        }
        &:active {
          background: var(--playbookColorActive);
        }
      }
      .btn.snapsheet {
        background-color: var(--snapsheetColor);
        border: 1px solid var(--snapsheetColor);
        &:hover {
          background: var(--snapsheetColorHover);
        }
        &:active {
          background: var(--snapsheetColorActive);
        }
      }
    }
    .selection-section {
      margin-bottom: 10px;
      .choose-category-label {
        width: 42%;
        max-width: 170px;
      }
      p {
        margin-bottom: 1.5px;
      }
      .form-control {
        width: auto;
        flex: 1;
        margin-left: 20px;
      }
      .sports-container {
        flex: 1;
        .form-group {
          flex: 1;
          .special-campaigns {
            display: flex;
            label {
              text-transform: capitalize;
              &.disabled {
                opacity: 0.8;
                pointer-events: none;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
    .templates-section {
      p {
        margin-bottom: 6px;
        font-weight: 500;
      }
    }
    .color-picker-row {
      display: flex;
      align-items: center;
      .input-group-column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .wiz-nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    flex-basis: auto;
    margin-bottom: 2.3rem;
    font-weight: 500;
    font-size: 1.15rem;
    p {
      /* color: black; */
    }
    &.active.showroom {
      color: var(--showroomColor);
      transition: all 0.2s;
      span {
        background-color: var(--showroomColor);
        transition: all 0.2s;
      }
    }
    &.active.playbook {
      color: var(--playbookColor);
      transition: all 0.2s;
      span {
        background-color: var(--playbookColor);
        transition: all 0.2s;
      }
    }
    &.active.snapsheet {
      color: var(--snapsheetColor);
      transition: all 0.2s;
      span {
        background-color: var(--snapsheetColor);
        transition: all 0.2s;
      }
    }

    span {
      width: 2.7rem;
      height: 2.7rem;
      font-size: 1.2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      background-color: #707070;
      color: #ffffff;
    }
  }

  .wiz-last-col {
    display: flex;
    flex-direction: column;

    .btn.showroom {
      background-color: var(--showroomColor);
      border: 1px solid var(--showroomColor);
      &:hover {
        background: var(--showroomColorHover);
      }
      &:active {
        background: var(--showroomColorActive);
      }
    }
    .btn.playbook {
      background-color: var(--playbookColor);
      border: 1px solid var(--playbookColor);
      &:hover {
        background: var(--playbookColorHover);
      }
      &:active {
        background: var(--playbookColorActive);
      }
    }
    .btn.snapsheet {
      background-color: var(--snapsheetColor);
      border: 1px solid var(--snapsheetColor);
      &:hover {
        background: var(--snapsheetColorHover);
      }
      &:active {
        background: var(--snapsheetColorActive);
      }
    }
  }

  .selection-section {
    margin-bottom: 10px;
    p {
      margin-bottom: 1.5px;
    }
    .form-control {
      width: auto;
      flex: 1;
      margin-left: 20px;
    }

    .school-scratch-selection-container {
      display: flex;
      justify-content: space-between;
      label {
        width: 100%;
        background: white;
        border: 2px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0 5px -1px #0000005e;
        color: black;
        padding: 5px;
        text-align: center;
        text-transform: uppercase;
        white-space: break-spaces;
        word-break: break-all;
        &.active {
          font-weight: 700;
        }

        &.active.showroom {
          border: 2px solid var(--showroomColor);
          span {
            background-color: var(--showroomColor);
          }
        }
        &.active.playbook {
          border: 2px solid var(--playbookColor);
          span {
            background-color: var(--playbookColor);
          }
        }
        &.active.snapsheet {
          border: 2px solid var(--snapsheetColor);

          span {
            background-color: var(--snapsheetColor);
          }
        }

        &.disabled {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }

  .wiz-submit-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin: 0 20px;
    .btn {
      font-family: "SlateStd";
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: -0.1px;
      font-size: 1.3rem;
    }

    .btn.showroom {
      background-color: var(--showroomColor);
      border: 1px solid var(--showroomColor);
      &:hover {
        background: var(--showroomColorHover);
      }
      &:active {
        background: var(--showroomColorActive);
      }
    }
    .btn.playbook {
      background-color: var(--playbookColor);
      border: 1px solid var(--playbookColor);
      &:hover {
        background: var(--playbookColorHover);
      }
      &:active {
        background: var(--playbookColorActive);
      }
    }
    .btn.snapsheet {
      background-color: var(--snapsheetColor);
      border: 1px solid var(--snapsheetColor);
      &:hover {
        background: var(--snapsheetColorHover);
      }
      &:active {
        background: var(--snapsheetColorActive);
      }
    }

    .loading-spinner {
      margin-right: 0 50px;
    }
  }

  .input-group-column {
    margin-bottom: 0.5rem;
    #uploaded-logo {
      .logo-container {
        margin: 10px 0;
        -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
        position: relative;
        max-width: 300px;
        padding: 10px;
        img {
          max-width: 300px;
          max-height: 150px;
        }
        #remove-logo {
          cursor: pointer;
          color: #ff3399;
          position: absolute;
          top: -20px;
          right: -15px;
          font-size: 24px;
        }
      }
    }
    .upload-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 1.2rem;
      }
      input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        width: 100%;
        height: 41px;
        background-color: #eee;
        max-width: 300px;
        padding: 10px;
        text-overflow: ellipsis;
        border: 1px solid #ccc;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
        span {
          color: #666;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  #rep-info {
    .email-input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 70%;
      }
      span {
        width: 30%;
        text-align: center;
        font-size: 1.2rem;
        color: black;
      }
      @media (max-width: 425px) {
        input {
          width: 60%;
        }
        span {
          width: 40%;
        }
      }
    }
  }

  #reps-dropdown {
    .border-radius-grey {
      border: 1px solid var(--buttonNotSelectedColor);
      border-radius: 4px;
    }
  }

  .rep-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    border: 1px dotted #2e2e2e;
    border-radius: 10px;
    .rep-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
      small {
        font-size: 14px;
      }
    }
    * {
      word-break: break-all;
      margin-bottom: 0.5rem;
      color: black;
      text-align: center;
    }
  }

  #override-pricing {
    align-items: baseline;
    .override-check-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      justify-content: flex-end;
      label {
        margin: 5px 0 0 5px;
      }
    }
  }
}
.project-sucess {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  h5 {
    color: white;
    padding: 1rem;
    border-radius: 5px;
    font-family: "SlateStd" !important;
    font-size: 1.4rem;

    &.showroom {
      background-color: var(--showroomColor);
    }

    &.playbook {
      background-color: var(--playbookColor);
    }

    &.snapsheet {
      background-color: var(--snapsheetColor);
    }
  }

  .create-another-project {
    color: black;
    font-weight: 700;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  #copied-msg {
    flex-direction: row-reverse;
  }
  .divider {
    width: 100%;
    border-bottom: 2px solid #eee;
    margin: 10px 0;
  }
  .form-group {
    margin-bottom: 5px;
    .input-group {
      label {
        margin: 0;
        padding: 10px 10px 10px 0;
      }
      .input-group-append {
        background-color: #3699ff;
        a {
          color: white;
          text-decoration: none;
          i {
            color: white;
          }
        }
      }
      #copy-project-link {
        cursor: pointer;
        color: #3699ff;
        -webkit-border-top-left-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        &:focus {
          border-color: #e2e5ec;
        }
      }
    }
  }
  h5 {
    text-transform: uppercase;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
  }
}
#dealer-mode {
  width: 100%;
  height: 1000px;
}
</style>
