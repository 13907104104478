import { render, staticRenderFns } from "./SchoolSearchBar.vue?vue&type=template&id=2cfa2dab&scoped=true&"
import script from "./SchoolSearchBar.vue?vue&type=script&lang=js&"
export * from "./SchoolSearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SchoolSearchBar.vue?vue&type=style&index=0&id=2cfa2dab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cfa2dab",
  null
  
)

export default component.exports