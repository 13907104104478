<template>
  <div
    class="color-picker-swatch flex align-center"
    @click="emitSelectColor({ color, field })"
  >
    <div
      class="color-picker-radius box-shadow"
      :class="{'little-border': color.name === 'WHITE' }"
      v-bind:style="{ backgroundColor: color.hex }"
    ></div>
    <span>{{ color.family_name }}</span>
  </div>
</template>

<script>
export default {
  props: ["color", "field"],
  name: "ColorSwatch",
  methods: {
    emitSelectColor(data) {
      this.$emit("emitSelectColor", data);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
#app {
  .color-picker-swatch {
    .color-picker-radius {
      width: 20px;
      height: 20px;
      &.little-border{
        border: 1px solid grey;
      }
    }
    cursor: pointer;
    &:hover {
      background-color: #303030;
      span {
        color: #ffffff;
      }
    }
    .color-picker-radius {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 5px;
    }
    span {
      color: #303030;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 0.76px;
      text-transform: lowercase;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
</style>