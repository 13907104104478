<template>
  <label
    class="sport-card flex-column flex align-center justify-end box-shadow"
    :class="{
      active: is_active,
      showroom: typeOfCard == 'showroom',
      playbook: typeOfCard === 'playbook',
    }"
    :for="sport.name"
  >
    <div class="icon flex" v-html="sport.svg_icon"></div>
    <input
      class="d-none"
      type="checkbox"
      :checked="is_active"
      :value="value"
      @change="selectSport"
      :name="sport.name"
      :id="sport.name"
    />
    <span>{{ sport.name }}</span>
  </label>
</template>

<script>
export default {
  name: "SportCard",

  model: {
    prop: "modelValue",
    event: "change",
  },

  props: {
    id: { type: Number },
    type: { type: String },
    sport: { type: Object },
    is_active: { type: Boolean },
    typeOfCard: { type: String },
    modelValue: { default: "" },
    value: { type: Object },
    trueValue: { default: true },
    falseValue: { default: false },
  },

  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    selectSport() {
      this.$emit("selectSport", this.sport.id);
    },
  },
};
</script>
<style lang="scss">
#app {
  .sport-card {
    width: 57px;
    height: 53px;
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 3px;
    margin: 5px;
    padding: 2px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    .icon {
      justify-content: center;
      svg {
        width: 25px;
        height: 25px;
        g {
          opacity: 0.5;
        }
      }
      ::v-deep svg {
        path,
        rect,
        line,
        ellipse {
          stroke: #000000;
          opacity: 0.5;
        }
      }
    }
    span {
      color: #000000;
      // font-family: $ubuntuBold;
      font-size: 8px;
      line-height: 9px;
      text-transform: uppercase;
      opacity: 0.5;
      margin-top: 5px;
    }
    &:hover {
      background-color: #ffffff;
      border-color: #000000;
      span {
        color: #000000;
        opacity: 1;
      }
      ::v-deep svg {
        path,
        rect,
        line,
        ellipse {
          stroke: #000000;
          opacity: 1;
        }
      }
    }
    &.active {
      &.showroom {
        border-color: var(--showroomColor);
      }
      &.playbook {
        border-color: var(--playbookColor);
      }
      &.snapsheet {
        border-color: var(--snapsheetColor);
      }
      span {
        opacity: 1;
      }
      ::v-deep svg {
        path,
        rect,
        line,
        ellipse {
          opacity: 1;
        }
      }
    }
  }
}
</style>